import { animate, query, sequence, stagger, state, style, transition, trigger } from '@angular/animations';

export const componentAnimation = [
  trigger('inOutAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate('0.25s ease-out', style({ opacity: 1 }))]),
    transition(':leave', [style({ opacity: 1 }), animate('0.25s ease-in', style({ opacity: 0 }))]),
  ]),
  trigger('inOutAnimationAbs', [
    transition(':enter', [
      style({ opacity: 0, position: 'absolute' }),
      animate('0.125s ease-out', style({ opacity: 1, position: 'absolute' })),
    ]),
    transition(':leave', [
      style({ opacity: 1, position: 'absolute' }),
      animate('0.125s ease-in', style({ opacity: 0, position: 'absolute' })),
    ]),
  ]),
  trigger('inOutAnimationSequence', [
    transition(':enter', [
      style({ opacity: 0, 'max-width': '0px' }),
      sequence([animate('0.25s ease-out', style({ 'max-width': '*' })), animate('0.125s ease-out', style({ opacity: 1 }))]),
    ]),
    transition(':leave', [
      style({ opacity: 1, 'max-width': '*' }),
      sequence([animate('0.125s ease-out', style({ opacity: 0 })), animate('0.25s ease-out', style({ 'max-width': '0px' }))]),
    ]),
  ]),
  trigger('quickInOutAnimation', [transition(':enter', [style({ opacity: 0 }), animate('0.25s ease-out', style({ opacity: 1 }))])]),
  trigger('leftPanelFormAnimation', [
    state('true', style({})),
    state('false', style({ transform: 'translateX(-80vw)', display: 'none', position: 'absolute' })),
    transition('false => true', [
      style({ position: 'relative', display: 'block' }),
      animate('0.5s ease-in', style({ transform: 'translateX(0vw)' })),
    ]),
  ]),
  trigger('rowExpend', [
    state('collapsed', style({ height: '0', opacity: '0' })),
    state('expanded', style({ height: '*', opacity: '1' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
  trigger('rightPanelFormAnimation', [
    state('true', style({})),
    state('false', style({ transform: 'translateX(80vw)', display: 'none', position: 'absolute' })),
    transition('false => true', [
      style({ position: 'relative', display: 'block' }),
      animate('0.5s ease-in', style({ transform: 'translateX(0vw)' })),
    ]),
  ]),
  trigger('drawerToggleAnimation', [
    state('true', style({ display: 'block' })),
    state('false', style({ display: 'none' })),
    // transition('false => true', [style({ display: 'block' })]),
    transition('true => false', [style({ display: 'none' })]),
  ]),
  // trigger('drawerToggleAnimation', [
  //   state('true', style({})),
  //   state('false', style({ transform: 'translateX(80vw)', display: 'none' })),
  //   transition('false => true', [
  //     style({ position: 'relative', display: 'block' }),
  //     animate('0.5s ease-in', style({ transform: 'translateX(0vw)' })),
  //   ]),
  // ]),
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0', opacity: '-1', display: 'none' })),
    state('expanded', style({ height: '*', opacity: '1', display: 'flex' })),
    transition('expanded => collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    transition('collapsed => expanded', [style({ display: 'flex' }), animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
  ]),
  trigger('cardSlideAnimation', [
    transition(':enter', [style({ height: '0px', opacity: 0 }), animate('0.25s ease-out', style({ height: '*', opacity: 1 }))]),
    transition(':leave', [style({ height: '*', opacity: 1 }), animate('0.25s ease-in', style({ height: '0px', opacity: 0 }))]),
  ]),
  trigger('componentFlexSlideIn', [
    transition(':enter', [style({ flex: 0, opacity: 0 }), animate('0.25s ease-out', style({ flex: 1, opacity: 1 }))]),
    transition(':leave', [style({ flex: 1, opacity: 1 }), animate('0.25s ease-in', style({ flex: 0, opacity: 0 }))]),
  ]),
  trigger('listAnimation', [
    transition('* <=> *', [
      query(':enter', [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))], { optional: true }),
      query(':leave', animate('200ms', style({ opacity: 0 })), { optional: true }),
    ]),
  ]),
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', 'min-height': '0' })),
    state('expanded', style({ height: '*' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
  trigger('buttonToInput', [
    transition(':enter', [style({ width: '2.75rem' }), animate('0.25s ease-out', style({ width: '*' }))]),
    transition(':leave', [style({ width: '*' }), animate('0.25s ease-in', style({ width: '2.75rem' }))]),
  ]),
];
